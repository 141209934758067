import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import FaqPageTemplate from "../../templates/faq-page"

const FaqPageEnglish = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <FaqPageTemplate {...props} />
    </Layout>
  )
}
export default FaqPageEnglish

export const pageQuery = graphql`
  query FaqPageEnglish {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/faq/index.en.md/" }
    ) {
      frontmatter {
        ...FaqPage
      }
    }
  }
`
